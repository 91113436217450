<template>
  <article class="ArticlePage">
    <div
        v-for="(article, i) in articles"
        :key="i"
        class="Wrapper ArticleArticlePage-Content"
    >
      <template v-if="articleId === article.id">
        <Article
            :title="article.title"
            :image="article.img"
            :disclaimer="article.disclaimer"
            :articles="article.articles"
        />
      </template>
    </div>
  </article>
</template>

<script>
import {mapGetters} from "vuex";
const Article = () => import(/* webpackPrefetch: true */ '@/components/Article.vue');

export default {
  name: "ArticlePage",
  components: {
    Article
  },
  computed: {
    ...mapGetters({
      articles: 'getArticles'
    }),
    articleId() {
      return this.$route.params.id;
    },
  }
}
</script>

<style lang="scss">

</style>